/* Footer.css */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #070D18;
  color: white;
  padding: 10px 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between; /* Center the footer content */
  align-items: center;
  gap: 20px; /* Add some space between text and social media links */
}

.footer-text {
  margin: 0;
}

.social-media-links {
  display: flex;
  gap: 15px;
}

.social-media-links a {
  color: white;
  text-decoration: none;
  font-size: 20px; /* Adjust icon size */
  transition: color 0.3s;
}

.social-media-links a:hover {
  color: #ddd;
}

@media (max-width: 780px) {
  .footer-text {
      display: none;
  }

  .footer-content {
      justify-content: center; /* Center social media links in the footer */
  }
}
