/* Join.css */
.join-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; /* Center vertically */
    align-items: center;     /* Center horizontally */
    min-height: 100vh;       /* Full viewport height */
    text-align: center;      /* Center text */
    padding: 20px;          /* Add padding */
  }

  .roare-flag {
    width: 30vw;
  }
  
  /* Styling for the Mailchimp form */
  #mc_embed_signup {
    background: #fff;
    clear: left;
    font: 14px Helvetica, Arial, sans-serif;
    width: 30vw; /* Adjust as needed */
    margin: 0 auto; 
    border-radius: 5px;
    padding: 10px;
    height: 75vh;
  }
  
  #mc_embed_signup h2 {
    color: #333; /* Set color to ensure visibility */
  }
  
  .indicates-required {
    color: #666; /* Set color to ensure visibility */
  }
  
  .mc-field-group {
    margin-bottom: 15px;
  }
  
  .mc-field-group label {
    color: #333; /* Set color to ensure visibility */
  }
  
  .mc-field-group input {
    display: block; /* Ensure input fields are visible */
    margin-top: 5px;
    padding: 8px;
    width: 100%;
    color: black;
  }
  
  .button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  @media only screen and (max-width: 1200px) {
    .join-roare-container {
        display: none;
    }

    #mc_embed_signup {
        width: 50vw; /* Adjust as needed */
        height: 100%;
      }
  }