@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

.home-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
	padding-top: 200px;
	padding-bottom: 50px;
}

* {
    color: white;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.school-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; /* centers horizontally */
    position: relative; /* Allows absolute positioning of child elements */
}

.school-name h1 {
    position: absolute; /* Stack h1 elements on top of each other */
    font-size: 7vw;
    margin: 0; /* Remove default margin */
}

.school-name h1:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px #05668D;
    z-index: 1;
}

.school-name h1:nth-child(2) {
    color: #64E9EE;
    animation: animate 4s ease-in-out infinite;
    z-index: 0;
}

.project-header {
	margin-top: 2vw;
	width: max-content;
	font-size: 1.3vw;
	color: white;
}

.headline {
	width: 55vw;
}

.join-button-container {
	display: flex;
	flex-direction: row;
	gap: 40px;
}


@keyframes animate {
    0%, 100% {
        clip-path: polygon(
            0% 45%,
            16% 44%,
            33% 50%,
            54% 60%,
            70% 61%,
            84% 59%,
            100% 52%,
            100% 100%,
            0% 100%
        );
    }
    50% {
        clip-path: polygon(
            0% 60%,
            15% 65%,
            34% 66%,
            51% 62%,
            67% 50%,
            84% 45%,
            100% 46%,
            100% 100%,
            0% 100%
        );
    }
}

@media only screen and (max-width: 780px) {
	.home-container {
		padding: 20vh 10vw 0;
		font-size: 1.9vh;
		justify-content: flex-start;
		gap: 4vh
	}

	.btn {
		font-size: 1.3vh;
	}

	.school-name h1 {
		font-size: 7vw;
	}
	
	.school-name h1:nth-child(1) {
		color: #64E9EE;
		-webkit-text-stroke: 0px;
	}

	.school-name h1:nth-child(2) {
		display: none;
	}

	.project-header{
		font-size: 1vh;
	}
	
	.headline {
		width: 100%;
	}

}