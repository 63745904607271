/* Button.css */
.btn {
    border: 1px solid #05668D;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
  }
  
  .btn:hover {
    background-color: #05668D;
  }
  
  .btn:active {
    transform: scale(0.98);
  }
  
  /* Optional: additional styles for different button types */
  .btn-primary {
    background-color: #007bff;
  }
  
  .btn-secondary {
    background-color: #6c757d;
  }
  
  .btn-danger {
    background-color: #dc3545;
  }
  
  .btn-success {
    background-color: #28a745;
  }
  