.header-container {
  background-color: #0B1423;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  color: white;
  padding: 0 30px;
  z-index: 1000; /* Ensure header is above other content */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 20px;
  border-bottom: 1px solid white;
}

.header a {
  text-decoration: none;
  color: white; 
}

.team-name {
  font-size: 1.4em;
  font-weight: bold;
}

/* Hide the links by default on smaller screens */
.nav-links {
  display: flex;
  gap: 20px;
}

.dropdown-icon {
  display: none;
  cursor: pointer;
  font-size: 1.5em;
  color: white;
}

/* Responsive design for smaller screens */
@media (max-width: 780px) {
  .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: #0B1423;
      position: absolute;
      top: 70px; /* Adjust based on your header height */
      left: 0;
      padding-bottom: 7px;
  }

  .nav-links.open {
      display: flex;
  }

  .header {
      justify-content: space-between;
  }

  .dropdown-icon {
      display: block;
  }
}
