.under-construction-page {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 40px;
}

.roare-wrench {
    width: 50vh;
}

.under-construction-text {
    font-size: 6vh;
    text-align: left;
    width: 50vw;
}

@media only screen and (max-width: 780px) {
    .under-construction-page {
        flex-direction: column-reverse;
        justify-content: center;
    }

    .roare-wrench {
        width: 10vh;
    }

    .under-construction-text {
        font-size: 2.7vh;
        text-align: center;
        width: 80vw;
    }
}